import React, { useEffect, useState } from "react"
import { graphql } from 'gatsby'
import { Header } from "../components/Header/Header"
import Reveal from "../components/reveal"
import { BuyerListing } from "../components/BuyerListing/BuyerListing"
import { Container } from "@c2fo/components__deprecated"
import { useLocation } from "@reach/router"
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { navigate } from "gatsby"
import SEO from "../components/Seo"

export default function Home({pageContext}) {
  const { language, languages } = useI18next()
  const { t } = useTranslation();
  const location = useLocation()
  const AUTH_PARAMS = ["auth_service_uuid", "account_id"]

  const [authParams, setAuthParams] = useState({})

  if (!location.search && typeof window !== `undefined`) {
    navigate("https://app.c2fo.com/login")
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const langParam = params.has('lang') ? params.get('lang') : '';

      if (langParam) {
        // if this was set by existing url param ignore
        params.delete('lang')
        const queryParamsString = params.toString();
        // browsers without delete() support may leave '?' https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/delete
        const cleanedQueryParamsString = queryParamsString.startsWith('?') ? queryParamsString : `?${queryParamsString}`;
        // navigate to supported language url /{language}/?{queryParams} after removing lang param
        // if `lang` param exists in language translations otherwise attempt to navigate to language section of locale
        // supported locale lang=zh-TW -> zh-TW/?{queryParams} 
        // unsuported locale lang=es-MX -> es/?{queryParams}
        navigate(`/${languages.includes(langParam) ? langParam : langParam.split('-')[0]}/${cleanedQueryParamsString}`)
      } else {
        setAuthParams(
          AUTH_PARAMS.reduce((authParams, key) => {
            if (params.has(key)) {
              authParams[key] = params.get(key)
            }
            return authParams
          }, {})
        )
      }
  }, [location])

  return (
    <>
      <SEO language={language} title={t('index.seo.title')} description={t('index.seo.title')} />
      <Reveal />
      <Header queryString={location.search}/>
      <Container>
        <BuyerListing params={authParams} />
      </Container>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
