import React from "react"
import { Paper, Grid, makeStyles } from "@c2fo/components__deprecated"

export const BuyerCard = props => {
  const useStyles = makeStyles(() => ({
    boxContainer: {
      display: `flex`,
      padding: `20px`,
      position: `relative`,
    },
    logo: {
      maxHeight: `110px`,
      margin: `0`,
      padding: `20px`,
    },
    checkbox: {
      height: "15px",
      width: "15px",
      border: `2px solid #e4e4e4`,
      position: `absolute`,
      right: `20px`,
      cursor: `pointer`,
    },
  }))

  const classes = useStyles()

  const buyer = props.buyer
  //this is to grab marketing information from the first division
  const division = buyer.divisions[0]

  return (
    <>
      {division && (
        <Grid item md={2}>
          <Paper>
            <Grid item className={classes.boxContainer}>
              <img
                className={classes.logo}
                src={buyer.buyerLogoPngUrl}
                alt={buyer.name}
              />
              <input
                type="checkbox"
                name={division.permalink}
                className={classes.checkbox}
                checked={props.selectedBuyers[division.permalink]}
                value={division.permalink}
                onChange={e => props.handleChange(e)}
              />
            </Grid>
          </Paper>
        </Grid>
      )}
    </>
  )
}
