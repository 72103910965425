import axios from "axios"

/*
 * sets userDivision state with array containing buyer
 * division uuids that is associated with the accountId query parameter
 */
export function fetchUserDivisions(auth_service_uuid, account_id) {
  return new Promise((resolve, reject) => {
    let data = JSON.stringify({
      authServiceUuid: auth_service_uuid,
      accountId: account_id,
    })
    axios
      .post("/.netlify/functions/lookupAccount", data, {
        headers: {
          accept: "application/json",
        },
      })
      .then(response => {
        resolve(response.data.response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/*
 * sets marketingBuyers state with all possible buyers
 */
export function fetchMarketingMakers() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/.netlify/functions/marketingMakers`)
      .then(response => {
        //sorts the response alphabetically by buyer name
        const sortedBuyers = response.data.sort(function (
          currentBuyer,
          nextBuyer
        ) {
          return currentBuyer.name.localeCompare(nextBuyer.name)
        })
        resolve(sortedBuyers)
      })
      .catch(err => {
        //redirect to login page
        reject(err)
      })
  })
}

/*
 * creates a new salesforce case with a user's email address
 * and the buyers selected by the user
 */
export const requestDivisionLink = (selectedBuyers, auth_service_uuid) => {
  let data = JSON.stringify({
    authServiceUuid: auth_service_uuid,
    finalBuyers: selectedBuyers,
  })

  return new Promise((resolve, reject) => {
    axios
      .post(`/.netlify/functions/requestDivisionLink`, data, {
        headers: {
          accept: "application/json",
        },
      })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
