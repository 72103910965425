import React, { useEffect, useState } from "react"
import { BuyerCard } from "../BuyerCard/BuyerCard"
import {
  Grid,
  makeStyles,
  TypeSectionHeader,
  TypeBase,
  Box,
  Button,
  CircularProgress,
} from "@c2fo/components__deprecated"
import {
  fetchUserDivisions,
  fetchMarketingMakers,
  requestDivisionLink,
} from "../../utils/BuyerListingUtils"
import {useTranslation, useI18next} from 'gatsby-plugin-react-i18next';


export const BuyerListing = ({ params: {auth_service_uuid, account_id}}) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();

  const useStyles = makeStyles(() => ({
    container: {
      maxWidth: `1300px`,
      margin: `0 auto`,
    },
    buttonContainer: {
      paddingTop: `80px`,
      textAlign: `center`,
    },
    buttonSelectAll: {
      backgroundColor: `#2C324F`,
      "&:hover": {
        backgroundColor: `#040A27`,
      },
      borderRadius: '2.8125rem',
      textTransform: 'none'
    },
    buttonConnectAccount: {
      backgroundColor: `#06A76C`,
      "&:hover": {
        backgroundColor: `#058556`,
      },
      borderRadius: '2.8125rem',
      textTransform: 'none'
    }
  
  }))

  const classes = useStyles()
  var initialBuyerCheckboxes = {}

  const [marketingBuyers, setMarketingBuyers] = useState(null)
  const [selectedBuyers, setSelectedBuyers] = useState({
    ...initialBuyerCheckboxes,
  })
  const [userDivisions, setUserDivisions] = useState([""])
  const [divisionDisplay, setDivisionDisplay] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  /*
   * loops through list of Marketing Buyers & Marketing Buyer divisions
   * returns an array with divisions attached to associated account
   */
  const generateBuyerDisplay = () => {
    let buyerDisplay = []
    let buyerNameList = []

    if (!userDivisions.length || !marketingBuyers) {
      return false
    }

    marketingBuyers.forEach(buyer => {
      if (buyer.divisions.length > 0) {
        buyer.divisions.forEach(division => {
          //checks if buyer exists in current array
          var checkBuyerArray = buyerDisplay.includes(buyer)
          if (
            userDivisions.includes(division.divisionUuid) &&
            !checkBuyerArray
          ) {
            buyerDisplay.push(buyer)
            buyerNameList.push(division.permalink)
          }
        })
      }
    })
    if (typeof window !== "undefined") {
      window.c2foData.digitalData.divlinkbase = buyerNameList.join("|")
    }
    setDivisionDisplay(buyerDisplay)
    setLoading(false)
    return buyerDisplay
  }

  useEffect(() => {
    fetchMarketingMakers().then(makers => {
      setMarketingBuyers(makers)
    })
    if (account_id) {
      fetchUserDivisions(auth_service_uuid, account_id)
        .then(data => {
          setUserDivisions(data)
        })
        .catch(err => {
          navigate("https://app.c2fo.com/login")
          setLoading(false)
        })
    }
  }, [auth_service_uuid, account_id])

  useEffect(() => {
    if (marketingBuyers) {
      generateBuyerDisplay()

      //updates the initalBuyerCheckboxes object to set inital state for the checkboxes
      marketingBuyers.map(buyer => {
        var division = buyer.divisions[0]
        if (division) {
          initialBuyerCheckboxes[division.permalink] = false
        }
      })
    }
  }, [userDivisions, marketingBuyers])

  const handleChange = e => {
    //updating an object instead of a Map
    setSelectedBuyers({
      ...selectedBuyers,
      [e.target.name]: e.target.checked,
    })
  }

  const selectAll = e => {
    let allUserBuyers = {};
    divisionDisplay.map((buyer) => {
      if (buyer.divisions[0]) {
        allUserBuyers[buyer.divisions[0].permalink] = true;
      }
    });
    setSelectedBuyers(allUserBuyers);
  }

  /*
   * creates a new salesforce case with a user's email address
   * and the buyers selected by the user
   */
  const onSubmit = () => {
    setLoading(true)
    var keys = Object.keys(selectedBuyers)
    //creates an array of buyer names that return true
    var finalSelection = keys.filter(key => {
      return selectedBuyers[key]
    })

    requestDivisionLink(finalSelection, auth_service_uuid)
      .then(response => {
        if (response.data.response.success) {
          navigate("/thank-you", {
            state: {
              selectedBuyers: finalSelection,
            },
          })
        } else {
          setLoading(false)
          setError(true)
        }
      })
      .catch(err => {
        setLoading(false)
        setError(true)
      })
  }

  return (
    <Grid container spacing={3} className={classes.container} justify="center">
      {loading && (
        <Box my={50}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box my={50}>
          <TypeBase isError={true}>
            {t('index.errorMsg')}
          </TypeBase>
        </Box>
      )}
      {!loading && (
        <>
          <Grid item xs={12}>
            <Box my={3}>
              <TypeSectionHeader>
                {t('index.heading')}
              </TypeSectionHeader>
              <TypeBase>
                {t('index.subHeading')}
              </TypeBase>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button className={classes.buttonSelectAll} onClick={() => selectAll()}>
              {t('index.selectAllBtnText')}
            </Button>
          </Grid>
          {divisionDisplay &&
            divisionDisplay.map((buyer, index) => (
              <BuyerCard
                key={index}
                buyer={buyer}
                checkboxState={initialBuyerCheckboxes}
                handleChange={handleChange}
                selectedBuyers={selectedBuyers}
              />
            ))}
          <Grid item xs={12} className={classes.buttonContainer}>
            <Box mt={5}>
            <Button  className={classes.buttonConnectAccount} href="#" onClick={() => onSubmit()}>
              {t('index.connectBtnText')}
            </Button>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  )
}
